var masonry = (function($) {
    'use strict';

    function init() {

        if ($('.m-grid').length) {
            $('.m-grid .grid-item:first-child').before("<div class='grid-sizer'></div>");
        }
        
        var $grid = $('.m-grid').masonry({
            columnWidth: '.grid-sizer',
            itemSelector: '.grid-item',
            horizontalOrder: true,
            percentPosition: true,
            gutter: 40
        });
        
        $grid.imagesLoaded().progress( function() {
          $grid.masonry();
        });
    }
    return {
        init: init
    };

}(jQuery));


export default masonry;