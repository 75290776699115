var wpmlCustom = (function($) {
    'use strict';

    function init() {

    	if ($('.wpml-ls-item').length) {
        	$('.wpml-ls-item').wrapAll('<li><ul class="wpml-list"></></>');
    	}
    }
    return {
        init: init
    };

}(jQuery));

export default wpmlCustom;