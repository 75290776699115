var mobileNav = (function($) {
    'use strict';

    function init() {
        $('.nav-opener').on('click', function(){
            $(this).toggleClass('open');
            $('html').toggleClass('nav-open');
            $('body').toggleClass('nav-open');
        });
    }

    return {
        init: init
    };

}(jQuery));


export default mobileNav;