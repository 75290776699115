var scrollTop = (function($) {
    'use strict';
    function init() {
        $(window).scroll(function(){
        if ($(this).scrollTop() > 200) {
            $('.back-to-top').fadeIn();
        } else {
            $('.back-to-top').fadeOut();
        }
    });
        $(".back-to-top").click(function() {
            $("html, body").animate({ scrollTop: 0 }, 800);
            return false;
        });
    }
    return {
        init: init
    };

}(jQuery));

export default scrollTop;