// You can write a call and import your functions in this file.
//
// This file will be compiled into app.js and will not be minified.
// Feel free with using ES6 here.

import StickyHeader from './modules/sticky-header.js';
import mobileNav from './modules/mobile-nav.js';
import owl from './modules/owl-init.js';
import sr from './modules/sr-init.js';
import smoothScroll from './modules/smooth-scroll.js';
import tabNav from './modules/tab-nav.js';
import dropdown from './modules/dropdown.js';
import parallaxFooter from './modules/parallax-footer.js';
import captionButtons from './modules/caption-buttons.js';
import dotdotdot from './modules/dotdotdot.js';
import masonry from './modules/masonry.js';
import gutterDisable from './modules/gutter-disable.js';
import fancybox from './modules/fancybox.js';
import scrollTop from './modules/scroll-top.js';
import embedPlayer from './modules/video-options.js';
import alt from './modules/alt.js';
import tabFocus from './modules/tab-focus.js';
import wpmlCustom from './modules/wpml-custom.js';


(($) => {
    'use strict';

    // When DOM is ready
    
    $(() => {
        StickyHeader.init();
        smoothScroll.init();
        tabNav.init();
        mobileNav.init();
        dropdown.init();
        parallaxFooter.init();
        captionButtons.init();
        gutterDisable.init();
        scrollTop.init();
        fancybox.init();
        tabFocus.init();
        wpmlCustom.init();
    });

    $(window).load(function() {
        masonry.init();
        embedPlayer.init();
        alt.init();
        dotdotdot.init();
        owl.init();
        setTimeout (function(){
            sr.init();
        }, 100);
    });

    $(window).resize(function() {
          dotdotdot.init();
    });

})(jQuery);