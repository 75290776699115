var smoothScroll = (function($) {
    'use strict';

    function init() {


        $(".scroll-button").click(function() {
            if ($(window).width() <= 1024) {
                $('html, body').animate({
                    scrollTop: $("#main").offset().top - 60
                }, 800);
            } else
                $('html, body').animate({
                scrollTop: $("#main").offset().top - 100
            }, 800);
        });

    }

    return {
        init: init
    };

}(jQuery));

export default smoothScroll;