var captionButtons = (function($) {
    'use strict';

    function init() {
        $('.caption p:has(a)').css("display", "inline-block");
        $('.caption p:has(a)').css("margin", "0");
    }
    return {
        init: init
    };

}(jQuery));


export default captionButtons;