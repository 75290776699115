var sr = (function($) {
    'use strict';
    function init() {
        $(document).ready(function() {
            $("body").on("mousedown", "*", function(e) {
                if (($(this).is(":focus") || $(this).is(e.target)) && $(this).css("outline-style") == "none") {
                    $(this).css("outline", "none").on("blur", function() {
                        $(this).off("blur").css("outline", "");
                    });
                }
                if (($(this).is(":focus") || $(this).is(e.currentTarget)) && $(this).css("outline-style") == "none") {
                    $(this).css("outline", "none").on("blur", function() {
                        $(this).off("blur").css("outline", "");
                    });
                }
            });
        });
    }
    return {
        init: init
    };

}(jQuery));


export default sr;