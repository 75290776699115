var gutterDisable = (function($) {
    'use strict';

    function init() {
        
        $('.allow-gutter p:has(img)').css("padding", "0");

    }
    return {
        init: init
    };

}(jQuery));


export default gutterDisable;