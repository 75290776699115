var fancybox = (function($) {
    'use strict';

    function init() {

        $().fancybox({
            selector: '[data-fancybox="gallery"]',
            loop: true
        });

    }

    return {
        init: init
    };

}(jQuery));


export default fancybox;