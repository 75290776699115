var embedPlayer = (function($) {
    'use strict';

    function init() {

        if ($('.vimeo-muted').length) {
            $('.vimeo-muted').each(function(index, el) {
                var player = new Vimeo.Player(el);
                player.setVolume(0);
                player.play();
            });
        }

    }
    return {
        init: init
    };

}(jQuery));

export default embedPlayer;