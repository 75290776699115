var StickyHeader = (function($) {
    'use strict';

    function init() {
        $(window).on("scroll", function() {
            var fromTop = $(window).scrollTop();
            $("#header").toggleClass("sticky", (fromTop > 1));
        });
        $(window).scroll();
    }
    return {
        init: init
    };
}(jQuery));


export default StickyHeader;