var dotdotdot = (function($) {
    'use strict';

    function init() {

        $(".mode-1 .caption  p, .mode-1 .title, .mode-2 .caption p, .mode-2 .title, .mode-4 .caption p, .mode-4 .title").each(function(index) {
            $(this).addClass('dotdotdot');
        });

        $(".list .bottom-text p").each(function(index) {
            $(this).addClass('dotdotdot');
        });

        $(".list .bottom-text .title").each(function(index) {
            $(this).addClass('dotdotdot');
        });

        $(".text-img-thumb .bottom-text p").each(function(index) {
            $(this).addClass('dotdotdot');
        });

        $(".text-img-thumb .bottom-text .title").each(function(index) {
            $(this).addClass('dotdotdot');
        });

        $(".mode-1 .title, .mode-2 .title, .mode-4 .title").each(function(index) {
            $(this).dotdotdot({
                height: null,
                watch: "true",
                truncate: "word",
                ellipsis: "..."
            });
        });

        $(".caption p.dotdotdot").each(function(index) {
            $(this).dotdotdot({
                height: null,
                watch: "true",
                truncate: "word",
                ellipsis: "..."
            });
        });

        $(".list .bottom-text p.dotdotdot").each(function(index) {
            $(this).dotdotdot({
                height: null,
                watch: "true",
                truncate: "word",
                ellipsis: "..."
            });
        });
        $(".list .bottom-text .title.dotdotdot").each(function(index) {
            $(this).dotdotdot({
                height: null,
                watch: "true",
                truncate: "word",
                ellipsis: "..."
            });
        });
        $(".text-img-thumb .bottom-text p.dotdotdot").each(function(index) {
            $(this).dotdotdot({
                height: null,
                watch: "true",
                truncate: "word",
                ellipsis: "..."
            });
        });

        $(".text-img-thumb .bottom-text .title.dotdotdot").each(function(index) {
            $(this).dotdotdot({
                height: null,
                watch: "true",
                truncate: "word",
                ellipsis: "..."
            });
        });

    }
    return {
        init: init
    };

}(jQuery));


export default dotdotdot;