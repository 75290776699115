var alt = (function($) {
    'use strict';

    function init() {
        $('.addtoany_shortcode div a').each(function () {
            $(this).find('svg').attr( 'alt', $(this).attr('title') );
         });
        $('a').each(function(){
            $(this).attr( 'role', 'link' );
        });
    }
    return {
        init: init
    };

}(jQuery));


export default alt;