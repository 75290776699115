var owl = (function($) {
    'use strict';

    function init() {

        $('.main-slider').owlCarousel({
            items: 1,
            nav: true,
            dots: true,
            autoHeight:true,
            onInitialized: callback
        });

        $('.video').owlCarousel({
            items: 1,
            nav: true,
            dots: true,
            autoHeight:true,
            onInitialized: callback
        });     
        
        $('.contact-slider').owlCarousel({
            items: 1,
            nav: true,
            dots: true,
            onInitialized: callback
        });

        $('.article-slider').owlCarousel({
            items: 1,
            nav: true,
            dots: true,
            onInitialized: callback
        });

        function callback(event) {
            var items = event.item.count;
            if (items <= 1) {
                $('.owl-carousel').addClass("single");
            }
        }
    }

    return {
        init: init
    };

}(jQuery));


export default owl;
