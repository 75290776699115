var sr = (function($) {
    'use strict';

    function init() {

        if ($('.item-inner').length) {

            var mq = window.matchMedia("(min-width: 1024px)");
            var sr = new ScrollReveal();
            var isMobile = sr.tools.isMobile();

            // ScrollReveal should proceed if we’re not mobile,
            // or if we’re mobile with a matching minimum width. 
            if (!isMobile || (isMobile && mq.matches)) {
                sr.reveal('.item-inner', {
                    viewFactor: 0.2,
                    duration: 1000,
                    reset: false
                });
            }
        }
    }
    return {
        init: init
    };

}(jQuery));


export default sr;