var dropdown = (function($) {
    'use strict';

    function init() {
      
        dropDirection();
        mobileDrop();

        $(window).resize(function() {
            dropDirection();
            mobileDrop();
        });

        function dropDirection() {
            if ($(window).width() > 1024) {
                $('.nav-list > .menu-item-has-children').each(function(index) {
                    var rw = ($(window).width() - ($(this).offset().left + $(this).outerWidth()));
                    $(this).toggleClass("to-right", (rw < 400.25));
                });
                $('.menu-item-has-children').hover(function() {
                    $(this).toggleClass('hover');
                });
            }
        }
        function mobileDrop() {
            if ($(window).width() <= 1024) {
                $('.nav-list > .menu-item-has-children').removeClass("to-right");
                $('.menu-item-has-children > a').off('click');
                $(".menu-item-has-children > a").one("click", function(event) {
                  event.preventDefault();
                });
                $('.menu-item-has-children > a').on('click', function() {
                    $(this).toggleClass('hover');
                    if ($(this).hasClass('hover')) {
                        $(this).next('.sub-menu').slideDown(300);
                    } else {
                        $(this).next('.sub-menu').slideUp(300);
                    }
                });
            }
        }

    }

    return {
        init: init
    };

}(jQuery));

export default dropdown;