var parallaxFooter = (function($) {
    'use strict';

    function init() {

        siteFooter();
        $(window).resize(function() {
            siteFooter();
        });

        function siteFooter() {
            var siteContent = $('#main');
            var siteContentHeight = siteContent.height();
            var siteContentWidth = siteContent.width();

            var siteFooter = $('#footer');
            var siteFooterHeight = siteFooter.height();
            var siteFooterWidth = siteFooter.width();

            if ($(window).width() > 1024) {
                $('#footer').css('position','fixed');
            }else {
                $('#footer').css('position','relative');
            }

            siteContent.css({
                "margin-bottom": siteFooterHeight + 147
            });
            if ($(window).width() < 1200) {
                siteContent.css({
                "margin-bottom": siteFooterHeight + 105
                });
            }
            if ($(window).width() <= 1024) {
                siteContent.css('margin-bottom', '0');
            }
        }

    }

    return {
        init: init
    };

}(jQuery));

export default parallaxFooter;